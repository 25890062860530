<template>
  <div class="page-container">
    <template v-if="oauthStatus == 0">
      <van-empty class="custom-image" description="登录中" />
    </template>
    <template v-if="oauthStatus == 1">
      <van-empty class="custom-image" description="跳转到登录页面..." />
    </template>
    <template v-if="oauthStatus == 2">
      <van-empty class="custom-image" description="登陆成功,跳转到登录前页面" />
    </template>
  </div>
</template>

<script>
import { oauthRedirect } from "@/api/oauth";

export default {
  data() {
    return {
      oauthStatus: 0,
    };
  },
  mounted() {
    if (this.$route.query.logout) {
      this.$store
        .dispatch("oauth/resetToken")
        .then(() => {})
        .catch(() => {});
      return;
    }
    let redirectUrl = this.$route.query.redirect
      ? this.$route.query.redirect
      : "/home";
    if (this.$route.query.code) {
      this.$store
        .dispatch("oauth/login", this.$route.query.code)
        .then(() => {
          //this.$router.push({ path: redirectUrl })
          window.location.replace("/m"+redirectUrl);
        })
        .catch(() => {});
    } else {
      this.oauthStatus = 1;
      oauthRedirect(redirectUrl).then((response) => {
        //window.location.href = response.targetUrl
        window.location.replace(response.targetUrl);
      });
    }
  },
};
</script>
